export const useGetVerCode = getVerCode => {
  const codeState = ref({
    time: 60,
    text: '获取验证码',
    isClick: true,
    isLoading: false
  });
  const timer = ref();
  const getVerCodeFn = async () => {
    codeState.value.isClick = false;
    codeState.value.isLoading = true;
    const res = await getVerCode();
    codeState.value.isLoading = false;
    if (res.code === 0) {
      timer.value = setInterval(() => {
        codeState.value.text = codeState.value.time + 's';
        if (codeState.value.time <= 0) {
          clearInterval(timer.value);
          codeState.value.time = 60;
          codeState.value.text = '重新获取';
          codeState.value.isClick = true;
        }
        codeState.value.time--;
      }, 1000);
    }
  };
  return {
    codeState,
    getVerCodeFn
  };
};